//iconSign:符号标志   1: 无图标    2: ≤    0:≥

const TypeOtherRules = [
  {
    key: 'clockTimeLimit',
    name: '服务事项中打卡定位间隔设置',
    content: [
      {
        key: 'clockLimit',
        name: '服务事项中设置了打卡定位，同一个人两次打卡时间的时间间隔不小于N分钟',
        unit: '分钟',
        hasInput: true,
        clockLimitFlag: 0,
        clockLimitNum: '',
        min: 1,
        max: 1440,
        iconSign: 0,
        defaultNum: '',
      },
    ],
  },
  {
    key: 'terminalNumLimit',
    name: '相同执行日期终端数量要求',
    content: [
      {
        key: 'terminalLimit',
        name: '每日的终端数量',
        unit: '个',
        hasInput: true,
        terminalLimitFlag: 0,
        terminalLimitNum: '',
        min: 0,
        max: 1000,
        iconSign: 2,
        defaultNum: '',
      },
    ],
  },
  {
    key: 'recordNumLimit',
    name: '相同执行日期记录条数要求',
    content: [
      {
        key: 'recordLimit',
        name: '每日的记录条数',
        unit: '条',
        hasInput: true,
        recordLimitFlag: 0,
        recordLimitNum: '',
        min: 0,
        max: 1000,
        iconSign: 2,
        defaultNum: '',
      },

    ],
  },
  {
    key: 'distinctImage',
    name: '照片重复校验',
    content: [
      {
        key: 'batchSelfUniqueImage',
        name: '在同一任务批次下的自己上传的图片与自己不可重复',
        hasInput: false,
        iconSign:1,
        batchSelfUniqueImageFlag: 0,
      },
      {
        key: 'batchUniqueImage',
        name: '在同一任务批次下自己上传图片与所有人不可重复 ',
        hasInput: false,
        iconSign:1,
        batchUniqueImageFlag: 0,
      },
      {
        key: 'uniqueImage',
        name: '同一服务事项的自己上传的照片在一定期限内与自己不可以重复(时间以执行日期为准)',
        unit: '个月以内',
        hasInput: true,
        uniqueImageFlag: 0,
        uniqueImageNum: '',
        min: 1,
        max: 12,
        iconSign: 1,
        defaultNum: 6,
      },
      {
        key: 'taskTypeUniqueImage',
        name: '同一服务事项的自己上传的照片在一定期限内与所有人不可以重复(时间以执行日期为准)',
        unit: '个月以内',
        hasInput: true,
        taskTypeUniqueImageFlag: 0,
        taskTypeUniqueImageNum: '',
        min: 1,
        max: 12,
        iconSign: 1,
        defaultNum: 6,
      },
      {
        key: 'selfUniqueImage',
        name: '自己上传的照片在一定期限内与自己不可重复(时间以执行日期为准)',
        unit: '个月以内',
        hasInput: true,
        selfUniqueImageFlag: 0,
        selfUniqueImageNum: '',
        min: 1,
        max: 6,
        iconSign: 1,
        defaultNum: 6,
      },
      {
        key: 'customerUniqueImage',
        name: '自己上传的照片在一定期限内与所有人不可重复(时间以执行日期为准)',
        unit: '个月以内',
        hasInput: true,
        customerUniqueImageFlag: 0,
        customerUniqueImageNum: '',
        min: 1,
        max: 12,
        iconSign: 1,
        defaultNum: 6,
      },
    ],
  },
  {
    key: 'publishArticle',
    name: '创作文章',
    content: [
      {
        key: 'articleWords',
        name: '创作文章字数应（正文）',
        unit: '字',
        hasInput: true,
        articleWordsFlag: 0,
        articleWordsNum: '',
        min: 1,
        max: 10000,
        defaultNum: 500,
      },

    ],
  },
  {
    key: 'publishTrends',
    name: '发表动态',
    content: [
      {
        key: 'image',
        name: '发表动态应至少包含图片数为',
        unit: '张',
        hasInput: true,
        imageFlag: 0,
        imageNum: '',
        min: 1,
        max: 9,
        defaultNum: 1,
      },
      {
        key: 'video',
        name: '发表动态应包含视频',
        unit: '字',
        hasInput: false,
        videoFlag: 0,

      },
      {
        key: 'trendsWords',
        name: '发表动态文字字数应',
        unit: '字',
        hasInput: true,
        trendsWordsFlag: 0,
        trendsWordsNum: '',
        min: 1,
        max: 500,
        defaultNum: 100,
      },

    ],
  },
  {
    key: 'publishQuestionAndAnswer',
    name: '发起问答',
    content: [
      {
        key: 'qaWords',
        name: '发起问答字数（内容）',
        unit: '字',
        hasInput: true,
        qaWordsFlag: 0,
        qaWordsNum: '',
        min: 1,
        max: 500,
        defaultNum: 50,
      },
      {
        key: 'reply',
        name: '问答回复数',
        unit: '个',
        hasInput: true,
        replyFlag: 0,
        replyNum: '',
        min: 0,
        max: 500,
        defaultNum: 3,
      },

    ],
  },
  {
    key: 'shareContract',
    name: '分享文章、分享动态',
    content: [
      {
        key: 'share',
        name: '分享浏览量应',
        unit: '次',
        hasInput: true,
        shareFlag: 0,
        shareNum: '',
        min: 0,
        max: 999999,
        defaultNum: 500,
      },
      {
        key: 'praise',
        name: '分享点赞量应',
        unit: '次',
        hasInput: true,
        praiseFlag: 0,
        praiseNum: '',
        min: 0,
        max: 999999,
        defaultNum: 500,
      },

    ],
  },
  {
    key: 'questionReplyAnswer',
    name: '回复问答',
    content: [
      {
        key: 'replyWords',
        name: '回复问答字数应',
        unit: '字',
        hasInput: true,
        replyWordsFlag: 0,
        replyWordsNum: '',
        min: 1,
        max: 500,
        defaultNum: 100,
      },

    ],
  },
  {
    key: 'liveContract',
    name: '创建直播',
    content: [
      {
        key: 'liveDuration',
        name: '直播时长应',
        unit: '分钟',
        hasInput: true,
        liveDurationFlag: 0,
        liveDurationNum: '',
        min: 1,
        max: 500,
        defaultNum: 15,
      },
      {
        key: 'participationPerson',
        name: '参加人数（不包含支持人）应',
        unit: '人',
        hasInput: true,
        participationPersonFlag: 0,
        participationPersonNum: '',
        min: 1,
        max: 500,
        defaultNum: 4,
      },

    ],
  },
  {
    key: 'participationContract',
    name: '参与直播',
    content: [
      {
        key: 'participationDuration',
        name: '参与时长应占总时长的',
        unit: '%',
        hasInput: true,
        participationDurationFlag: 0,
        participationDurationNum: '',
        min: 1,
        max: 100,
        defaultNum: 10,
      },

    ],
  },
  {
    key: 'participationExam',
    name: '参与答题',
    content: [
      {
        key: 'examScore',
        name: '答题分数应',
        unit: '分',
        hasInput: true,
        examScoreFlag: 0,
        examScoreNum: '',
        min: 1,
        max: 100,
        defaultNum: 80,
      },

    ],
  },
  {
    key: 'meetingContract',
    name: '发起会议',
    content: [
      {
        key: 'meetingDuration',
        name: '会议时长应',
        unit: '分钟',
        hasInput: true,
        meetingDurationFlag: 0,
        meetingDurationNum: '',
        min: 1,
        max: 500,
        defaultNum: 15,
      },
      {
        key: 'acceptPerson',
        name: '参加人数（不包含支持人）应',
        unit: '人',
        hasInput: true,
        acceptPersonFlag: 0,
        acceptPersonNum: '',
        min: 1,
        max: 500,
        defaultNum: 4,
      },

    ],
  },
]
export default TypeOtherRules

